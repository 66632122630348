export const blankAddr = '0x0000000000000000000000000000000000000000';
export const testAddr = '';
export const V2Url = "https://fivexw.com/#/"
export const serverLink = 'https://service.fivexw.com';
export const FomoActionAddress='0xeca502305351fDE61B4fAc9F53bFAeEEB11604Af';

// prod
export const shareAddress = '0x7fB089474f6D165a847A52cE042294911DC8c1bE';
export const contractAddr = [
    "0xA95Ac52D4011311E684A3Fc5dDAC28cE57c6bb7a",
    "0x47c9eBE09B494489385c5897b2A48BBf7d19a441",
  ];
export const scoreContractAddr = '0x278713C90ee31bcfC320EF86086C055bB55cF144';
export const actionContractAddr = '0xfdB223C0dfba93423abcfAF0A365581aD4Fb53B2';
export const rewardBagAddress = '0xB47B9db5Fe5dfaE5242b4E382F3b748d687BD017';
export const scoreDecimal = 18;
export const tokenOptions = [
    {
        name: 'AURA',
        decimal: 18,
        address: '0x745e5A2f4dB6C78C71911E86E5978990a3630666',
        unitDecimal: 0,
        unitName: ''
    },
];
